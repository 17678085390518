import styled from "styled-components";

export const Container = styled.footer`
  width: 100%;
  height: auto;

  background-color: #8ad2cd;

  display: flex;
  flex-wrap: wrap;

  justify-content: flex-end;
  align-items: center;

  position: relative;

  bottom: 0;

  padding: 7px 0;
`;

export const Anchor = styled.a`
  font-family: Poppins Light;
  font-size: 1em;

  color: #000000;

  margin: 7px;

  text-decoration: none;

  @media only screen and (max-width: 1200px) {
    font-size: 0.8em;
  }
`;

export const Image = styled.img`
  cursor: pointer;

  height: 25px;
`;
