import { Container, Title, Subtitle, Text, Tile, Image, Row } from "./styles";

export function Solutions() {
  return (
    <Container>
      <Title>Soluções Acceptore</Title>
      <Text>
        A experiência acumulada pela Acceptore, nos levou a disponibilizar
        templates que aceleram o processo de implantação e são configurados
        conforme as características e demandas de cada organização.
      </Text>
      <Row>
        <Tile>
          <Image src={"images/solution1.png"} alt={"Gestão de Pessoas"} />
          <Subtitle>Gestão de Pessoas</Subtitle>
        </Tile>
        <Tile>
          <Image src={"images/solution2.png"} alt={"Custos"} />
          <Subtitle>Custos</Subtitle>
        </Tile>
      </Row>
      <Row>
        <Tile>
          <Image src={"images/solution3.png"} alt={"Instituições de Ensino"} />
          <Subtitle>Instituições de Ensino</Subtitle>
        </Tile>
        <Tile>
          <Image src={"images/solution4.png"} alt={"Agro"} />
          <Subtitle>Agro</Subtitle>
        </Tile>
      </Row>
    </Container>
  );
}
