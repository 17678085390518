import styled from "styled-components";

export const Overlay = styled.div<{ open: boolean }>`
  width: 100%;
  height: calc(100vh - 80px);

  position: absolute;

  ${({ open }) => !open && "display: none;"}
`;

export const Container = styled.aside<{ open: boolean }>`
  position: absolute;

  z-index: 20;

  width: 100%;
  height: auto;

  background-color: #555555aa;

  transition: all 0.6s ease-in-out;

  ${({ open }) =>
    open
      ? "transform: translateY(0);"
      : "transform: translateY(calc(-500px));"};
`;

export const Button = styled.button<{ selected: boolean }>`
  width: 100%;
  height: 50px;

  background-color: ${({ selected }) => (selected ? "#1fcdd0" : "#55555500")};
  color: #ffffff;

  border: none;

  font-family: Poppins;
  font-size: 1em;

  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${({ selected }) => (selected ? "#1fcdd0" : "#1fcdd066")};
  }

  &:active {
    background-color: #1fcdd0;
  }
`;
