import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { main, aboutUs, solutions, services, clients, contact } from "./paths";
import { Main, AboutUs, Services, Solutions, Clients, Contact } from "views";

export default function Routes() {
  return (
    <Switch>
      <Route exact path={main()} render={() => <Main />} />
      <Route exact path={aboutUs()} render={() => <AboutUs />} />
      <Route exact path={solutions()} render={() => <Solutions />} />
      <Route exact path={services()} render={() => <Services />} />
      <Route exact path={clients()} render={() => <Clients />} />
      <Route exact path={contact()} render={() => <Contact />} />
      <Redirect to={main()} />
    </Switch>
  );
}
