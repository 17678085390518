import { useHistory } from "react-router";
import { contact } from "routes/paths";

import { Container, Anchor, Image } from "./styles";

export function Footer() {
  const history = useHistory();

  return (
    <Container>
      <Anchor href={"tel:+5511944791100"}>
        <Image src={"/images/whatsapp.png"} />
      </Anchor>
      <Anchor href={"tel:+5551997695468"}>
        <Image src={"/images/telegram.png"} />
      </Anchor>
      <Anchor href={"emailto:'contato@acceptore.com.br'"}>
        <Image src={"/images/email.png"} />
      </Anchor>
      <Anchor href={"https://br.linkedin.com/company/acceptore-consulting"}>
        <Image src={"/images/linkedin.png"} />
      </Anchor>
      <Anchor onClick={() => history.push(contact())}>
        <Image src={"/images/skyline.png"} />
      </Anchor>
    </Container>
  );
}
