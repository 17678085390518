import { Container, Section, Title, Text, Image } from "./styles";

export function AboutUs() {
  return (
    <Container>
      <Section>
        <Image src={"images/aboutus.jpg"} alt={"About us"} simple={false} />
        <div>
          <Title>Quem Somos</Title>
          <Text>
            A Acceptore acredita que a melhor forma para se organizar, crescer e
            se desenvolver é planejar e agir estrategicamente. A partir dessa
            crença criamos uma consultoria especializada em soluções de gestão e
            planejamento, focados em desenvolver soluções construídas com base
            nas melhores práticas para o seu negócio. Desenvolvemos soluções que
            levem ao cliente melhor desempenho para seus negócios e por
            consequência melhorias em seus processos e desenvolvimento dos seus
            profissionais. Nosso principal objetivo é agregar valor aos negócios
            dos nossos clientes.
          </Text>
        </div>
      </Section>
      <Section>
        <div>
          <Title>Equipe</Title>
          <Text>
            A Acceptore possui ampla experiência e vivência em todas as fases de
            um projeto de implementação, apoiando ao cliente na escolha da
            melhor ferramenta, desenvolvendo em conjunto o plano de projeto e
            acompanhando na gestão e implementação dessa solução em empresas de
            vários segmentos. Nossa equipe acompanha o processo de ponta a ponta
            gerando assim maior sinergia e segurança.A equipe de consultores
            Acceptore é formada por consultores altamente qualificados e com
            ampla experiência, tendo atuado em projetos de consultoria em mais
            de 50 empresas de grande porte no Brasil e América Latina.
          </Text>
        </div>
        <Image src={"images/team.jpg"} alt={"Team"} simple={false} />
      </Section>
      <Title>Alianças</Title>
      <Text>
        Temos um forte relacionamento e comprometimento com nossos parceiros.
        Oferecendo assim toda confiança aos clientes.
      </Text>
      <Image src={"images/oracle_partner.png"} alt={"Oracle"} simple={true} />
    </Container>
  );
}
