import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  min-height: calc(100% - 138px);

  display: flex;
  flex-direction: column;

  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
`;

export const Header = styled.section`
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
`;

export const Tiles = styled.div`
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
`;

export const Title = styled.h1`
  font-family: Poppins Extra Bold;
  font-size: 2.2em;

  color: #222222;
`;

export const Subtitle = styled.h3`
  font-family: Poppins;
  font-size: 1em;

  color: #333;
`;

export const Text = styled.h3`
  font-family: Poppins;
  font-size: 1em;

  color: #444444;

  text-align: justify;

  width: 90%;
`;

export const Explanation = styled.h3`
  font-family: Poppins;
  font-size: 1em;

  color: #444444;

  text-align: center;

  width: 90%;
`;

export const Tile = styled.div`
  cursor: pointer;

  width: 300px;
  height: 120px;

  background-color: white;

  border-top: 2px dashed #4cece1;

  margin: 10px;

  display: flex;

  align-items: center;

  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.01);
  }
`;

export const Image = styled.img`
  height: 90px;
  width: 90px;

  border-radius: 50%;

  margin: 10px;

  border: 0.5px solid grey;
`;

export const Button = styled.button<{ selected: boolean }>`
  cursor: pointer;

  border: ${({ selected }) =>
    selected ? `1px solid #6bd6cf` : `1px solid grey`};
  border-radius: 7px;

  padding: 10px;
  margin: 6px;

  color: ${({ selected }) => (selected ? `#ffffff` : `#333333`)};
  background-color: ${({ selected }) => (selected ? `#6bd6cf` : `#ffffff`)};

  font-family: Poppins;
  font-size: 1em;

  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #6bd6cf;
    color: #ffffff;

    border: 1px solid #6bd6cf;
  }
`;
