import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  height: fit-content;
`;

export const Services = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  background-color: #ffffff;
`;

export const Tiles = styled.div`
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
`;

export const Title = styled.h1`
  font-family: Poppins Bold;
  font-size: 2em;

  color: #000000;
`;

export const Explanation = styled.p`
  font-family: Poppins Light;
  font-size: 1.3em;

  color: #444444;

  width: 90%;

  text-align: justify;

  @media only screen and (max-width: 1200px) {
    font-size: 1em;

    width: 80%;
  }
`;

export const Subtitle = styled.h3`
  font-family: Poppins;
  font-size: 1em;

  color: #333;

  margin: 15px;
`;

export const Text = styled.p`
  font-family: Poppins Light;
  font-size: 1em;

  color: #444;

  margin: 15px;
`;

export const Tile = styled.div`
  cursor: pointer;

  width: 200px;
  height: 300px;

  background-color: white;

  border-top: 2px dashed #4cece1;

  display: flex;
  flex-direction: column;

  align-items: center;

  transition: all 0.3s ease-in-out;

  &:hover {
    @media only screen and (min-width: 1200px) {
      transform: scale(1.02);
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    width: 300px;
  }
`;

export const Image = styled.img`
  height: 90px;

  margin: 10px;
`;
