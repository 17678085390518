import data from "data/services.json";

import { Container, Title, Text, Tiles, Tile, Image, Subtitle } from "./styles";

export function Services() {
  return (
    <Container>
      <Title>Serviços Acceptore</Title>
      <Text>
        Desenvolvemos serviços específicas para aumento de produtividade e
        efetividade das organizações. Nossa maior função é utilizar melhores
        práticas no desenvolvimento de nossas soluções para alcance rápido dos
        resultados.
      </Text>
      <Tiles>
        {data.tiles.map((tile, i) => (
          <Tile index={i}>
            <Image src={`images/services${i + 1}.png`} />
            <div>
              <Subtitle>{tile.name}</Subtitle>
              <Text>{tile.sentence}</Text>
            </div>
          </Tile>
        ))}
      </Tiles>
    </Container>
  );
}
