import { useState, useEffect, useCallback, useMemo } from "react";

import {
  Container,
  Slide,
  ArrowRightIcon,
  ArrowLeftIcon,
  Text,
} from "./styles";

const data = [
  { name: "sea", sentence: "Agilidade com as soluções Acceptore" },
  {
    name: "trees",
    sentence: "Gestão de performance gerando melhores negócios",
  },
  { name: "sky", sentence: "Medotologia moderna pensada para nuvem" },
];

export function Carousel() {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [direction, setDirection] = useState<"right" | "left">("right");
  const [initial, setInitial] = useState<Array<number | null>>([null, null]);

  const imageUrls: Array<string> = useMemo(() => ["sea", "trees", "sky"], []);

  const moveSlideToThe = useCallback(
    (dir: "right" | "left") => {
      setDirection(dir);
      const lastIndex = imageUrls.length - 1;

      if (direction === "right") {
        const shouldResetIndex = currentImageIndex === lastIndex;
        setCurrentImageIndex(shouldResetIndex ? 0 : currentImageIndex + 1);
      } else {
        const shouldResetIndex = currentImageIndex === 0;
        setCurrentImageIndex(
          shouldResetIndex ? lastIndex : currentImageIndex - 1
        );
      }
    },
    [direction, currentImageIndex, setCurrentImageIndex, imageUrls]
  );

  const startTouch: any = useCallback((e: TouchEvent) => {
    setInitial([e.touches[0].clientX, e.touches[0].clientY]);
  }, []);

  const endTouch: any = useCallback(
    (e: TouchEvent) => {
      const currentX = e.changedTouches[0].clientX;
      const currentY = e.changedTouches[0].clientY;

      if (!initial[0] || !initial[1]) {
        return;
      }

      if (Math.abs(initial[0] - currentX) > Math.abs(initial[1] - currentY)) {
        if (initial[0] - currentX > 0) {
          return moveSlideToThe("left");
        } else {
          return moveSlideToThe("right");
        }
      }
    },
    [initial, moveSlideToThe]
  );

  useEffect(() => {
    const slider = setTimeout(() => {
      moveSlideToThe("right");
    }, 7000);

    return () => {
      clearTimeout(slider);
    };
  }, [moveSlideToThe]);

  return (
    <Container onTouchStart={startTouch} onTouchEnd={endTouch}>
      <ArrowLeftIcon onClick={() => moveSlideToThe("left")} />
      <ArrowRightIcon onClick={() => moveSlideToThe("right")} />
      <Slide
        url={`images/${imageUrls[currentImageIndex]}.jpeg`}
        direction={direction}
        key={currentImageIndex}
      >
        <Text>{data[currentImageIndex].sentence}</Text>
      </Slide>
    </Container>
  );
}
