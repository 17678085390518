import {
  Container,
  Services,
  Tiles,
  Title,
  Text,
  Explanation,
  Subtitle,
  Tile,
  Image,
} from "./styles";
import { Carousel } from "components";

import servicesData from "data/mainServices.json";

export function Main() {
  return (
    <Container>
      <Carousel />
      <Services>
        <Title>{servicesData.title}</Title>
        <Explanation>{servicesData.explanation}</Explanation>
        <Tiles>
          {servicesData.tiles.map((tile, i) => (
            <Tile key={i}>
              <Image src={`images/services${i + 1}.png`} />
              <div>
                <Subtitle>{tile.name}</Subtitle>
                <Text>{tile.sentence}</Text>
              </div>
            </Tile>
          ))}
        </Tiles>
      </Services>
    </Container>
  );
}
