import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  min-height: calc(100% - 138px);

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

export const Tile = styled.div`
  width: 270px;
  height: 270px;

  border: double #4cece1;
  border-width: 4px 9px;
  border-radius: 50%;

  background-color: #ffffff;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 35px;

  box-shadow: 0 0 10px #00000044;

  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 20px #00000066;
  }

  @media only screen and (max-width: 787px) {
    width: 230px;
    height: 230px;
  }
`;

export const Title = styled.h1`
  font-family: Poppins Extra Bold;
  font-size: 2.2em;

  color: #222222;

  text-align: center;
`;

export const Subtitle = styled.h1`
  font-family: Poppins;
  font-size: 2em;

  color: #222222;

  text-align: center;

  @media only screen and (max-width: 787px) {
    font-size: 1.7em;
  }
`;

export const Text = styled.h3`
  font-family: Poppins;
  font-size: 1em;

  color: #444444;

  text-align: justify;

  width: 90%;
`;

export const Image = styled.img`
  width: 100px;

  @media only screen and (max-width: 787px) {
    width: 70px;
  }
`;

export const Row = styled.div`
  width: 100%;

  display: flex;

  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;
