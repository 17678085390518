import { useState } from "react";
import data from "data/clients.json";

import {
  Container,
  Title,
  Text,
  Explanation,
  Tile,
  Tiles,
  Subtitle,
  Image,
  Button,
  Header,
  Buttons,
} from "./styles";

const translate = (value: string) => {
  switch (value) {
    case "industry":
      return "Indústria";
    case "agro":
      return "Agro";
    case "education":
      return "Educação";
    case "retail":
      return "Varejo";
    case "services":
      return "Serviços";
    default:
      break;
  }
};

export function Clients() {
  const [category, setCategory] = useState<string | null>(null);

  return (
    <Container>
      <Header>
        <Title>Clientes</Title>
        <Explanation>
          Implementações de sucesso. Clientes Satisfeitos.
        </Explanation>
        <Buttons>
          <Button
            onClick={() => setCategory(null)}
            selected={category === null}
          >
            Todos
          </Button>
          <Button
            onClick={() => setCategory("industry")}
            selected={category === "industry"}
          >
            Indústria
          </Button>
          <Button
            onClick={() => setCategory("agro")}
            selected={category === "agro"}
          >
            Agro
          </Button>
          <Button
            onClick={() => setCategory("education")}
            selected={category === "education"}
          >
            Educação
          </Button>
          <Button
            onClick={() => setCategory("retail")}
            selected={category === "retail"}
          >
            Varejo
          </Button>
          <Button
            onClick={() => setCategory("services")}
            selected={category === "services"}
          >
            Serviços
          </Button>
        </Buttons>
      </Header>
      <Tiles>
        {data.tiles.map(
          (tile, i) =>
            ((category && category === tile.category) || !category) && (
              <Tile key={i}>
                <Image
                  src={`images/${tile.name
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")}.png`}
                  alt={tile.name}
                />
                <div>
                  <Subtitle>{tile.name}</Subtitle>
                  <Text>{translate(tile.category)}</Text>
                </div>
              </Tile>
            )
        )}
      </Tiles>
    </Container>
  );
}
