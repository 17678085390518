import { useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import {
  main,
  aboutUs,
  solutions,
  services,
  clients,
  contact,
} from "routes/paths";

import { Nav, Container, Image, Button, MenuIcon } from "./styles";
import { Menu } from "./Menu/Menu";

export function Navbar() {
  const history = useHistory();
  const location = useLocation();

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const onClickHandler = useCallback(
    (path: string) => {
      history.push(path);
      setOpenMenu(false);
    },
    [history]
  );

  return (
    <Nav>
      <Container>
        <Image
          src={"images/logo.png"}
          alt={"Acceptore"}
          onClick={() => onClickHandler(main())}
        />
        <div>
          <Button
            selected={`/${location.pathname.split("/")[1]}` === aboutUs()}
            onClick={() => onClickHandler(aboutUs())}
          >
            Quem Somos
          </Button>
          <Button
            selected={`/${location.pathname.split("/")[1]}` === solutions()}
            onClick={() => onClickHandler(solutions())}
          >
            Soluções
          </Button>
          <Button
            selected={`/${location.pathname.split("/")[1]}` === services()}
            onClick={() => onClickHandler(services())}
          >
            Serviços
          </Button>
          <Button
            selected={`/${location.pathname.split("/")[1]}` === clients()}
            onClick={() => onClickHandler(clients())}
          >
            Clientes
          </Button>
          <Button
            selected={`/${location.pathname.split("/")[1]}` === contact()}
            onClick={() => onClickHandler(contact())}
          >
            Contatos
          </Button>
        </div>
        <MenuIcon onClick={() => setOpenMenu(!openMenu)} />
      </Container>
      <Menu openMenu={openMenu} setOpenMenu={setOpenMenu} />
    </Nav>
  );
}
