import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  min-height: calc(100% - 138px);

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  font-family: Poppins Extra Bold;
  font-size: 2.8em;

  color: #222222;

  text-align: center;

  @media only screen and (max-width: 767px) {
    font-size: 2em;
  }
`;

export const Subtitle = styled.h3`
  font-family: Poppins Extra Bold;
  font-size: 2.2em;

  color: #333;

  text-align: center;

  @media only screen and (max-width: 767px) {
    font-size: 1.2em;
  }

  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    font-size: 1.7em;
  }
`;

export const Data = styled.a`
  font-family: Poppins Extra Bold;
  font-size: 2.2em;

  color: #333;

  text-align: center;

  text-decoration: none;

  margin: 10px 0;

  @media only screen and (max-width: 767px) {
    font-size: 1.2em;
  }

  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    font-size: 1.7em;
  }
`;

export const Text = styled.h3`
  font-family: Poppins;
  font-size: 1.4em;

  color: #444444;

  text-align: center;

  width: 90%;

  margin: 10px;

  @media only screen and (max-width: 767px) {
    font-size: 1.2em;
  }
`;

export const Row = styled.div`
  width: 100%;

  display: flex;

  justify-content: space-evenly;
  align-items: center;

  @media only screen and (max-width: 834px) {
    flex-direction: column;

    align-items: center;
  }
`;

export const HR = styled.hr`
  width: 90%;
  height: 1.5px;

  background-color: #4cece1;

  border: none;
`;

export const Image = styled.img`
  height: 450px;

  @media only screen and (max-width: 767px) {
    width: 300px;
    height: auto;
  }

  margin: 25px 0;
`;
