import { useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { aboutUs, solutions, services, clients, contact } from "routes/paths";

import { Container, Button, Overlay } from "./styles";

interface Props {
  openMenu: boolean;
  setOpenMenu: (value: boolean) => void;
}

export function Menu({ openMenu, setOpenMenu }: Props) {
  const history = useHistory();
  const location = useLocation();

  const onClickHandler = useCallback(
    (path: string) => {
      history.push(path);
      setOpenMenu(false);
    },
    [history, setOpenMenu]
  );

  return (
    <>
      <Container open={openMenu}>
        <Button
          selected={`/${location.pathname.split("/")[1]}` === aboutUs()}
          onClick={() => onClickHandler(aboutUs())}
        >
          Quem Somos
        </Button>
        <Button
          selected={`/${location.pathname.split("/")[1]}` === solutions()}
          onClick={() => onClickHandler(solutions())}
        >
          Soluções
        </Button>
        <Button
          selected={`/${location.pathname.split("/")[1]}` === services()}
          onClick={() => onClickHandler(services())}
        >
          Serviços
        </Button>
        <Button
          selected={`/${location.pathname.split("/")[1]}` === clients()}
          onClick={() => onClickHandler(clients())}
        >
          Clientes
        </Button>
        <Button
          selected={`/${location.pathname.split("/")[1]}` === contact()}
          onClick={() => onClickHandler(contact())}
        >
          Contatos
        </Button>
      </Container>
      <Overlay open={openMenu} onClick={() => setOpenMenu(false)} />
    </>
  );
}
