import {
  Container,
  Title,
  Subtitle,
  Text,
  Row,
  HR,
  Image,
  Data,
} from "./styles";

export function Contact() {
  return (
    <Container>
      <Title>Contato com a Acceptore</Title>
      <Text>Fale conosco por telefone, e-mail e/ou mídias sociais.</Text>
      <Row>
        <Data href={"tel:+5511944791100"}>(11) 94479-1100</Data>
        <Data href={"tel:+5551997695468"}>(51) 99769-5468</Data>
        <Data href={"emailto:'contato@acceptore.com.br'"}>
          contato@acceptore.com.br
        </Data>
      </Row>
      <HR />
      <Row>
        <div>
          <Subtitle>Matriz</Subtitle>
          <Text>São Paulo - SP</Text>
          <Text>Bela Vista, Avenida Paulista, 1.079</Text>
          <Text>8º andar, Torre João Salem</Text>
        </div>
        <Image src={"images/contact.png"} />
      </Row>
    </Container>
  );
}
