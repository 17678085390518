import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  min-height: calc(100% - 138px);

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

export const Tiles = styled.div`
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
`;

export const Title = styled.h1`
  font-family: Poppins Extra Bold;
  font-size: 2.2em;

  color: #222222;

  text-align: center;
`;

export const Subtitle = styled.h3`
  font-family: Poppins Extra Bold;
  font-size: 1em;

  color: #333;

  text-align: center;
`;

export const Text = styled.h3`
  font-family: Poppins Light;
  font-size: 1em;

  color: #444444;

  text-align: justify;

  width: 90%;
`;

export const Tile = styled.div<{ index: number }>`
  cursor: pointer;

  width: 300px;
  height: 320px;

  background-color: white;

  border-top: 2.5px dashed #4cece1;

  margin: 10px;

  display: flex;
  flex-direction: column;

  align-items: center;

  transition: all 0.3s ease-in-out;

  &:hover {
    @media only screen and (min-width: 1200px) {
      transform: scale(1.02);
    }
  }
`;

export const Image = styled.img`
  height: 75px;

  margin: 25px 10px 5px 10px;
`;
