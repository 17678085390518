import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "routes";

import { Container } from "./styles";
import { Navbar, Footer } from "components";

export default function App() {
  return (
    <Router>
      <Container>
        <Navbar />
        <Routes />
        <Footer />
      </Container>
    </Router>
  );
}
