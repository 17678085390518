import styled from "styled-components";
import { ArrowRight, ArrowLeft } from "@styled-icons/feather";

interface Props {
  url: string;
  direction: "right" | "left";
}

export const Container = styled.div`
  width: 100%;
  height: 700px;
`;

export const Slide = styled.div<Props>`
  max-width: 100%;
  height: 700px;

  display: flex;

  justify-content: center;
  align-items: center;

  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: 1200px) {
    justify-content: center;
  }
`;

export const ArrowRightIcon = styled(ArrowRight)`
  cursor: pointer;

  background-color: #1fcdd0;
  color: white;

  width: 40px;
  height: 40px;

  border-radius: 50%;

  box-shadow: 0 0 10px #00000044;

  position: absolute;

  top: 50%;
  right: 32px;

  z-index: 10;

  transition: all 0.3s ease-in-out;

  @media only screen and (max-width: 1200px) {
    display: none;
  }

  &:hover {
    box-shadow: 0 0 10px #000000dd;
  }
`;

export const ArrowLeftIcon = styled(ArrowLeft)`
  cursor: pointer;

  background-color: #1fcdd0;
  color: white;

  width: 40px;
  height: 40px;

  border-radius: 50%;

  box-shadow: 0 0 10px #00000044;

  position: absolute;

  top: 50%;
  left: 32px;

  z-index: 10;

  transition: all 0.3s ease-in-out;

  @media only screen and (max-width: 1200px) {
    display: none;
  }

  &:hover {
    box-shadow: 0 0 10px #000000dd;
  }
`;

export const Text = styled.h3`
  font-family: Poppins Extra Bold;
  font-size: 4em;

  color: white;

  text-shadow: 0 0 10px #00000055;

  width: 57%;

  @media only screen and (max-width: 1200px) {
    font-size: 2.5em;

    width: 90%;

    text-align: center;
  }
`;
