import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  min-height: calc(100% - 138px);

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

export const Section = styled.section`
  width: 100%;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }

  > div {
    width: 35%;

    margin: 10px;

    @media only screen and (max-width: 1200px) {
      width: 90%;

      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;
    }
  }
`;

export const Title = styled.h1`
  font-family: Poppins Extra Bold;
  font-size: 2.2em;

  color: #222222;

  border-bottom: 2px dashed #4cece1;

  @media only screen and (max-width: 1200px) {
    margin: 10px 0;
  }
`;

export const Text = styled.h3`
  font-family: Poppins;
  font-size: 1em;

  color: #444444;

  text-align: justify;

  @media only screen and (max-width: 1200px) {
    width: 90%;
  }
`;

export const Image = styled.img<{ simple: boolean }>`
  width: 550px;

  margin: 40px 10px;

  ${({ simple }) => !simple && `box-shadow: 0 0 10px #00000077`};

  transition: all 0.3s ease-in-out;

  @media only screen and (max-width: 787px) {
    width: 90%;

    margin: 30px 10px 10px 10px;
  }

  &:hover {
    ${({ simple }) => !simple && `box-shadow: 0 0 10px #000000bb`};
  }
`;
