import styled from "styled-components";
import { Menu as Icon } from "@styled-icons/feather";

export const Nav = styled.nav`
  position: sticky;
  top: 0;

  z-index: 30;
`;

export const Container = styled.nav`
  width: 100%;
  height: 80px;

  display: flex;

  justify-content: space-between;
  align-items: center;

  background-color: #555555;

  box-shadow: 0 0 10px #000000;

  position: sticky;
  top: 0;

  z-index: 30;
`;

export const Button = styled.button<{ selected: boolean }>`
  cursor: pointer;

  border: none;
  border-radius: 7px;

  padding: 10px;
  margin: 6px;

  background-color: ${({ selected }) => (selected ? "#1fcdd0" : "#555555")};
  color: white;

  font-family: Poppins;
  font-size: 1em;

  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #1fcdd0;
  }

  &:active {
    background-color: #1fcdd0;
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const Image = styled.img`
  cursor: pointer;

  width: 135px;

  margin-left: 13px;
`;

export const MenuIcon = styled(Icon)`
  cursor: pointer;

  width: 33px;
  height: 33px;

  color: white;

  margin: 0 20px;

  @media only screen and (min-width: 1200px) {
    display: none;
  }
`;
